import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import OnlineService from '../components/OnlineService'

const OnlineServicePage = () => {
  return (
    <Layout>
      <SEO title="온라인 예배 | 말씀 - 죠이플 교회" description="Online Service for Sunday and weekday worship services" />
      <OnlineService />
    </Layout>
  )
}

export default OnlineServicePage
